import React, { useMemo, PropsWithChildren } from "react"
import { RelatedProduct } from "../../types/related-product"
import { PathPrefix } from "../../core/constants"
import { Carousel } from "../carousel/carousel"
import { Product, ProductProps } from "../product/product"
import { SpinnerButton } from "../button/spinner-button"
import styles from "./labelled-product-list.module.scss"
import { LabelledProductSkeleton } from "./labelled-product-skeleton"

export type ProductCardMapper<T> = (
  input: T
) => Omit<ProductProps, "variant" | "size">

export interface LabelledProductListProps<T> {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  description: string | JSX.Element
  products: T[]
  buttonLabel?: string | JSX.Element
  buttonLink?: string
  buttonTestId?: string
  mapProduct: ProductCardMapper<T>
  separator?: JSX.Element
  onButtonClick?: () => void
  isProcessing?: boolean
  isLoading?: boolean
}

export const mapRelatedProductToProductCard = (
  input: RelatedProduct
): Omit<ProductProps, "variant" | "size"> => ({
  author: input.author ? input.author.name : "",
  authorLink: input.author ? `${PathPrefix.Teachers}/${input.author.slug}` : "",
  image: input.image,
  url: input.link,
  title: input.title,
  handle: input.handle || "",
  format: input.format
})

export function LabelledProductList<T>({
  title,
  subtitle,
  description,
  products,
  buttonLabel,
  buttonLink,
  buttonTestId,
  mapProduct,
  separator,
  onButtonClick,
  isLoading = false,
  isProcessing = false
}: PropsWithChildren<LabelledProductListProps<T>>): JSX.Element {
  const items = useMemo(() => products.map(mapProduct), [mapProduct, products])

  return (
    <div className={styles.labelledProductList}>
      <div className={styles.textContent}>
        {subtitle && <h5 className={styles.label}>{subtitle}</h5>}
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        {buttonLabel && (
          <SpinnerButton
            to={buttonLink}
            dataTestId={buttonTestId}
            onClick={!buttonLink ? onButtonClick : undefined}
            variant="secondary"
            size="sm"
            processing={isProcessing}
          >
            {buttonLabel}
          </SpinnerButton>
        )}
      </div>
      <div className={styles.products}>
        <Carousel
          separator={separator}
          itemClassName={styles.carouselItem}
          className={styles.carousel}
        >
          {isLoading
            ? Array(3)
                .fill(`skel-key`)
                .map((a, i) => <LabelledProductSkeleton key={`${a}-${i}`} />)
            : items.map((item) => (
                <Product
                  key={item.url}
                  {...item}
                  size="sm"
                  variant={item.disableDropShadow ? "flat" : "shadow"}
                />
              ))}
        </Carousel>
      </div>
    </div>
  )
}
