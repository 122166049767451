import { FluidObject } from "gatsby-image"
import {
  ContentfulHomePageYearEndHeaderSimple,
  ContentfulHomePageYearEndHeaderVideo,
  ContentfulHomePageYearEndHeaderQuote,
  ContentfulHomePageYearEndHeaderTestimonies,
  ContentfulHomePageYearEndHeaderCountdown
} from "../../../../graphql/types"
import {
  YearEndHeaderData,
  YearEndHeaderType,
  YearEndContent
} from "../home-page-types"

export const mapYearEndHeader = (
  gqlData: YearEndHeaderData
): YearEndContent => {
  const { __typename } = gqlData

  const headerType = __typename
    .replace("ContentfulHomePageYearEndHeader", "")
    .toLowerCase() as YearEndHeaderType

  const simpleHeaderData = gqlData as ContentfulHomePageYearEndHeaderSimple
  const videoHeaderData = gqlData as ContentfulHomePageYearEndHeaderVideo
  const quoteHeaderData = gqlData as ContentfulHomePageYearEndHeaderQuote
  const testimoniesHeaderData =
    gqlData as ContentfulHomePageYearEndHeaderTestimonies
  const countdownHeaderData =
    gqlData as ContentfulHomePageYearEndHeaderCountdown

  const outputMap = {
    simple: {
      headline:
        simpleHeaderData.headline?.childMarkdownRemark?.rawMarkdownBody || "",
      subhead:
        simpleHeaderData.subhead?.childMarkdownRemark?.rawMarkdownBody || "",
      buttonText: simpleHeaderData.buttonText || "",
      buttonLink: simpleHeaderData.buttonLink || "",
      imageFluid: simpleHeaderData.image?.fluid as FluidObject | undefined
    },
    video: {
      headline:
        videoHeaderData.headline?.childMarkdownRemark?.rawMarkdownBody || "",
      imageFluid: videoHeaderData.image?.fluid as FluidObject | undefined,
      buttonText: videoHeaderData.buttonText || "",
      buttonLink: videoHeaderData.buttonLink || ""
    },
    quote: {
      quoteText:
        quoteHeaderData.quoteText?.childMarkdownRemark?.rawMarkdownBody || "",
      quoteAttribution:
        quoteHeaderData.quoteAttribution?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      subhead:
        quoteHeaderData.subhead?.childMarkdownRemark?.rawMarkdownBody || "",
      imageFluid: videoHeaderData.image?.fluid as FluidObject | undefined,
      buttonText: videoHeaderData.buttonText || "",
      buttonLink: videoHeaderData.buttonLink || ""
    },
    testimonies: {
      headline:
        testimoniesHeaderData.headline?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      subhead:
        testimoniesHeaderData.subhead?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      quote1Text:
        testimoniesHeaderData.quote1Text?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      quote1Attribution:
        testimoniesHeaderData.quote1Attribution?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      quote1Image: testimoniesHeaderData.quote1Image?.fluid as
        | FluidObject
        | undefined,
      quote2Text:
        testimoniesHeaderData.quote2Text?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      quote2Attribution:
        testimoniesHeaderData.quote2Attribution?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      quote2Image: testimoniesHeaderData.quote2Image?.fluid as
        | FluidObject
        | undefined,
      quote3Text:
        testimoniesHeaderData.quote3Text?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      quote3Attribution:
        testimoniesHeaderData.quote3Attribution?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      quote3Image: testimoniesHeaderData.quote3Image?.fluid as
        | FluidObject
        | undefined,
      buttonText: testimoniesHeaderData.buttonText || "",
      buttonLink: testimoniesHeaderData.buttonLink || ""
    },
    countdown: {
      headline:
        countdownHeaderData.headline?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      buttonText: countdownHeaderData.buttonText || "",
      buttonLink: countdownHeaderData.buttonLink || "",
      targetDate: countdownHeaderData.targetDate,
      imageFluid: countdownHeaderData.image?.fluid as FluidObject | undefined
    }
  }

  return {
    ...outputMap[headerType],
    headerType
  }
}
