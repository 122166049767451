import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { useIntl } from "react-intl"
import Image, { FluidObject } from "gatsby-image"
import { PathPrefix, Store } from "../../core/constants"
import { Link } from "../link/link"
import { ResourceType } from "../../types/resource"
import { TranslateableString } from "../../types/intl"
import { getStringTranslator } from "../../i18n/helpers"
import styles from "./custom-card-resource.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface CustomCardResourceProps {
  slug: string
  url?: string
  title: string
  type?: ResourceType
  image?: string
  imageFluid?: FluidObject
  bodyText?: string
  subtitle?: TranslateableString
  time?: number
  titleTag?: keyof JSX.IntrinsicElements
}

const cardUrl: Record<ResourceType, string> = {
  article: PathPrefix.Articles,
  guide: PathPrefix.Guides,
  collection: PathPrefix.Collections,
  conference: PathPrefix.Conferences,
  devotional: PathPrefix.Devotional,
  event: PathPrefix.Events,
  mediaResource: PathPrefix.MediaResource,
  podcastEpisode: PathPrefix.Podcasts,
  podcastShow: PathPrefix.Podcasts,
  post: PathPrefix.Post,
  product: Store,
  question: PathPrefix.QuestionsAnswered,
  series: PathPrefix.Series,
  sermon: PathPrefix.Sermon,
  video: PathPrefix.Video
}

export const CustomCardResource: FunctionComponent<CustomCardResourceProps> = ({
  image,
  imageFluid: imageFluidProp,
  slug,
  url: urlProp,
  bodyText,
  subtitle,
  title,
  type = "article",
  titleTag: Tag = "div"
}) => {
  const url = type === "video" ? slug : `${cardUrl[type]}/${slug}`
  const intl = useIntl()
  const translateString = getStringTranslator(intl)

  const imageFluid =
    imageFluidProp || getFluidGatsbyImage(image || "", { maxWidth: 800 })

  return (
    <Link className={styles.cardResource} to={urlProp || url}>
      <div className={styles.content}>
        <Tag className={styles.title}>
          <span>{title}</span>
        </Tag>
        <div className={styles.bodyText}>
          <div className={styles.innerBodyText}>{bodyText}</div>
        </div>
        <div className={styles.subtitle}>
          {subtitle && translateString(subtitle)}
        </div>
      </div>
      {imageFluid ? (
        <Image className={styles.image} fluid={imageFluid} />
      ) : (
        <div
          className={classNames(
            styles.image,
            image ? styles.fallback : styles.placeholder
          )}
          style={{
            backgroundImage: `url(${
              image || "/assets/image-placeholders/card-logo.svg"
            })`
          }}
        />
      )}
    </Link>
  )
}
