import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./labelled-product-skeleton.module.scss"
import { Skeleton } from "@components/skeleton/skeleton"

export interface LabelledProductSkeletonProps {
  className?: string
}

export const LabelledProductSkeleton: FunctionComponent<
  LabelledProductSkeletonProps
> = ({ className }) => (
  <div className={classNames(className, styles.container)}>
    <Skeleton className={styles.flex} width={`90px`} height={`12px`} />
    <Skeleton className={styles.flex} width={`200px`} height={`200px`} />
    <Skeleton className={styles.flex} width={`70px`} height={`12px`} />
    <Skeleton className={styles.flex} height={`16px`} />
    <Skeleton className={styles.flex} width={`40px`} height={`12px`} />
  </div>
)
