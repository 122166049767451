import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { HomePageListItem } from "../../home-page-types"
import { HomePageList } from "./home-page-list"
import styles from "./home-page-lists.module.scss"

export interface HomePageListsProps {
  popular: HomePageListItem[] | null
  recent: HomePageListItem[] | null
}

export const HomePageLists: FunctionComponent<HomePageListsProps> = ({
  popular,
  recent
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.lists}>
      {popular && (
        <HomePageList
          label={formatMessage({ id: "homepage-popular" })}
          link=""
          description={formatMessage({ id: "homepage-popular-description" })}
          items={popular}
        />
      )}
      {recent && (
        <HomePageList
          label={formatMessage({ id: "homepage-recent" })}
          link="/search/?sort=newest"
          description={formatMessage({ id: "homepage-recent-description" })}
          items={recent}
        />
      )}
    </div>
  )
}
