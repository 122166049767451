import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { RelatedProduct } from "../../types/related-product"
import {
  LabelledProductList,
  mapRelatedProductToProductCard
} from "../labelled-product-list/labelled-product-list"
import { useDynamicProducts } from "../../features/store/hooks/use-dynamic-products"
import { Store } from "@core/constants"

export interface FromStoreProps {
  products: RelatedProduct[]
}

export const FromStore: FunctionComponent<FromStoreProps> = ({ products }) => {
  const dynamicProducts = useDynamicProducts<RelatedProduct>(products)

  return (
    <LabelledProductList
      title={<FormattedMessage id="store-from-store" />}
      subtitle={<FormattedMessage id="store-related-resources" />}
      description={<FormattedMessage id="store-related-products-header" />}
      products={dynamicProducts as unknown[] as RelatedProduct[]}
      buttonLabel={<FormattedMessage id="store-view-all-button" />}
      buttonLink={Store}
      mapProduct={mapRelatedProductToProductCard}
    />
  )
}
