import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { Entry } from "contentful"
import styles from "../home-page-view/home-page-view.module.scss"
import useDailyResources from "./use-daily-resources"
import { ImageEntry } from "@components/featured-resources/types"
import { Resource } from "@src-types/resource"
import { PathPrefix } from "@core/constants"
import { FeaturedResources } from "@components/featured-resources/featured-resources"
import {
  ScriptureReference,
  formatScriptureReference,
  getBookLocaliser
} from "@features/scriptures/mappers/scripture-reference-mapper"
import { urlDate } from "@utils/date"
import { CustomCardResource } from "@components/card/custom-card"

interface DailyResourcesProps {
  labelVariant?: "light" | "dark"
}

export const DailyResources: FunctionComponent<DailyResourcesProps> = ({
  labelVariant = "dark"
}) => {
  const { dailyVideo, dailyDevotional } = useDailyResources()

  const videoFields = dailyVideo?.fields?.video?.fields
  const devotionalFields = dailyDevotional?.fields?.devotional?.fields

  const videoTeacher = videoFields?.teachers?.[0]?.fields

  const videoSubtitle = [videoTeacher?.firstName, videoTeacher?.lastName].join(
    " "
  )

  const videoDate = dailyVideo?.fields?.displayDate

  const videoSlug =
    videoFields?.slug && videoDate
      ? `${PathPrefix.DailyVideo}/${urlDate(videoDate)}/${videoFields.slug}`
      : ""

  const devotionalScriptureReference =
    devotionalFields?.primaryScriptureReference ||
    devotionalFields?.secondaryScriptureReferences?.[0]

  const { formatMessage } = useIntl()
  const getLocalisedBook = getBookLocaliser(formatMessage)

  const devotionalSubtitle = devotionalScriptureReference
    ? formatScriptureReference(
        devotionalScriptureReference as ScriptureReference,
        getLocalisedBook
      )
    : ""

  const list: Resource[] = [
    {
      title: "Renewing Your Mind",
      type: "podcastShow",
      url: "https://renewingyourmind.org?utm_source=ligonier&utm_medium=homepage",
      slug: "",
      image:
        "/assets/image-placeholders/renewing-your-mind-placeholder-800.jpg",
      datePublished: 3
    },
    {
      title: dailyVideo?.fields?.title || "",
      slug: videoSlug,
      avatar: videoTeacher?.image?.fields?.file?.url,
      subtitle: videoSubtitle,
      image: (videoFields?.series?.fields?.image as Entry<ImageEntry>)?.fields
        ?.file?.url,
      type: "video",
      datePublished: 2
    },
    {
      title: devotionalFields?.name || "",
      slug: devotionalFields?.slug || "",
      avatar: "",
      subtitle: devotionalSubtitle,
      type: "devotional",
      datePublished: 1
    },

    // custom card info
    {
      title: "Get the Ligonier App",
      url: "https://www.ligonier.org/app",
      slug: "",
      subtitle: "Download the app today.",
      bodyText: "Faithful teaching every day, wherever you go.",
      image: "/assets/image-placeholders/Ligonier_app_download_card_image.jpg",
      datePublished: 1,
      component: CustomCardResource
    }
  ]

  return (
    <FeaturedResources
      className={styles.featured}
      labelId="homepage-daily-resources"
      labelCollapse
      list={list}
      showResourceTypes
      bypassRequiredListLength
      labelVariant={labelVariant}
    />
  )
}
