import { FluidObject } from "gatsby-image"
import { CallToActionFragment } from "../../../graphql/types"
import { CallToAction } from "../../types/call-to-action"

export const mapCallToAction = (
  data: CallToActionFragment | null | undefined
): CallToAction | null => {
  if (!data) {
    return null
  }

  const imageFluid = data.image?.fluid as FluidObject

  const result: CallToAction = {
    title: data.title || "",
    overline: data.subtitle || "",
    buttonText: data.buttonText || "",
    buttonUrl: data.buttonUrl || "",
    ...(imageFluid && { imageFluid }),
    body: data.body?.childMarkdownRemark?.rawMarkdownBody || ""
  }

  const notEmptyValuesCount = Object.values(result).filter((val) => val).length

  if (notEmptyValuesCount === 0) {
    return null
  }

  return result
}
