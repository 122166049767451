import React, { Fragment, FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { RelatedProduct } from "../../../../types/related-product"
import { CallToAction } from "../../../../types/call-to-action"
import { Resource } from "../../../../types/resource"
import { HomePageLists } from "../home-page-lists/home-page-lists"
import {
  HomePageListItem,
  HomePageCustomPromotion,
  HomePageProductPromotion,
  YearEndContent
} from "../../home-page-types"
import { ProductHeader } from "../../../store/components/product-header/product-header"
import { DailyResources } from "../home-page-daily-resources/home-page-daily-resources"
import styles from "./home-page-view.module.scss"
import {
  PromoHeader,
  PromoHeaderProps
} from "@components/promo-header/promo-header"
import { SearchBox } from "@components/search-box/search-box"
import { CallToAction as CallToActionView } from "@components/call-to-action/call-to-action"
import { FeaturedResources } from "@components/featured-resources/featured-resources"
import { EventsList } from "@components/events-list/events-list"
import { Event } from "@features/events/event-types"
import { FromStore } from "@components/from-store/from-store"

export type FeaturedList = (Resource | null)[] | null

export interface HomePageViewProps {
  fromStore: RelatedProduct[] | null
  upcomingEvents: Event[] | null
  featuredForYou: FeaturedList
  additionalFeatured: FeaturedList
  popularList: HomePageListItem[] | null
  recentList: HomePageListItem[] | null
  callToAction: CallToAction | null
  productPromotion?: HomePageProductPromotion
  customPromotion?: HomePageCustomPromotion
  featuredEvent?: Event | null
  displayFeaturedEvent?: boolean
  yearEndHeader?: YearEndContent | null
}

export const HomePageView: FunctionComponent<HomePageViewProps> = ({
  fromStore,
  upcomingEvents,
  featuredForYou,
  additionalFeatured,
  productPromotion,
  customPromotion,
  popularList,
  recentList,
  callToAction,
  featuredEvent,
  displayFeaturedEvent,
  yearEndHeader
}) => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <div
        className={
          yearEndHeader ? styles.yearEndHeader : styles.homePageContent
        }
      >
        {yearEndHeader && (
          <SearchBox
            className={styles.searchBox}
            placeholder={formatMessage({ id: "header-search-placeholder" })}
          />
        )}
        {featuredForYou && (
          <FeaturedResources
            className={styles.featured}
            list={featuredForYou}
            labelCollapse
            labelId="homepage-for-you"
            labelVariant={yearEndHeader ? "dark" : "light"}
          />
        )}
        {productPromotion ? (
          <ProductHeader
            product={productPromotion.product}
            variant={productPromotion.product.activeVariant}
            headline={productPromotion.headline}
            subhead={productPromotion.subhead}
            buttonText={productPromotion.buttonText}
            showSecondaryLink={productPromotion.showSecondaryLink}
            secondaryLinkText={productPromotion.secondaryLinkText}
            headerLocation="homepage"
          />
        ) : (
          <PromoHeader {...(customPromotion as PromoHeaderProps)} />
        )}
        {additionalFeatured && (
          <FeaturedResources
            className={styles.featured}
            labelCollapse
            labelId="homepage-featured"
            list={additionalFeatured}
          />
        )}
        <HomePageLists popular={popularList} recent={recentList} />
        <DailyResources />
        {fromStore && <FromStore products={fromStore} />}
        {callToAction && <CallToActionView {...callToAction} />}
        {upcomingEvents && (
          <EventsList
            featuredEvent={featuredEvent}
            displayFeaturedEvent={displayFeaturedEvent}
            list={upcomingEvents}
            variant="secondary"
          />
        )}
      </div>
    </Fragment>
  )
}
