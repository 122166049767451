import React, { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "../../../../components/link/link"
import { getStringTranslator } from "../../../../i18n/helpers"
import { Icon } from "../../../../components/icon/icon"
import { List, ListItemGeneric } from "../../../../components/list/list"
import { HomePageListItem } from "../../home-page-types"
import styles from "./home-page-lists.module.scss"

export interface HomePageListProps {
  label: string
  link?: string
  description: string
  items: HomePageListItem[]
}

export const HomePageList: FunctionComponent<HomePageListProps> = ({
  label,
  link,
  description,
  items
}) => {
  const intl = useIntl()
  const translateString = getStringTranslator(intl)

  return (
    <div className={styles.list}>
      <header>
        <div className={styles.label}>{label}</div>
        {link && (
          <div className={styles.link}>
            <Link
              to={link}
              aria-label="See more recent resources on Ligonier.org"
            >
              <FormattedMessage id="homepage-see-more" />
            </Link>
            <Icon variant="16-right-arrow" />
          </div>
        )}
      </header>
      <p className={styles.description}>{description}</p>
      <div className={styles.content}>
        <List ordered variant="results" size="fluid">
          {items.map((item) => (
            <ListItemGeneric
              key={item.link}
              meta={translateString(item.meta)}
              title={item.title}
              url={item.link}
              type={item.type}
            />
          ))}
        </List>
      </div>
    </div>
  )
}
