import { Maybe } from "../../../../graphql/types"
import { TranslateableString } from "../../../types/intl"
import { HomePageListItem, ResourceNode } from "../home-page-types"
import { typeMap } from "../home-page-constants"
import { getLinkForResource, getMapperForResource } from "./common-mappers"

export const mapSourceListToView = (
  nodes: Maybe<ResourceNode>[] | null | undefined
): HomePageListItem[] | null => {
  if (!nodes) {
    return null
  }

  return nodes!.map((item) => {
    const resourceMapper = getMapperForResource(item!)
    const resource = resourceMapper(item!)
    const link = getLinkForResource(item!, resource!.slug!)

    const meta: TranslateableString[] = []

    if (resource!.subtitle) {
      meta.push(resource!.subtitle)
    }

    if (resource!.time) {
      meta.push(`${resource!.time}MIN`)
    }

    return {
      title: resource!.title,
      type: typeMap[item!.internal.type],
      link,
      meta
    }
  })
}
