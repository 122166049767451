import set from "lodash/set"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Store } from "../../../core/constants"
import { useStore } from "./use-store"

export interface ProductWithHandle {
  handle: string
}

export function useDynamicProducts<T>(
  sourceProducts: T[],
  path?: string
): ProductWithHandle[] {
  const mapProducts = useCallback((list: T[]): ProductWithHandle[] => {
    return list.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const link = (item as any).link.replace(`${Store}`, "")
      const handle = link.substring(
        link.startsWith("/") ? 1 : 0,
        link.endsWith("/") ? link.length - 1 : link.length
      )

      return {
        ...item,
        handle: handle
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const productWithHandles = useMemo(
    () => mapProducts(sourceProducts),
    [mapProducts, sourceProducts]
  )

  const [dynamicProducts, setDynamicProducts] =
    useState<ProductWithHandle[]>(productWithHandles)
  const { availabilitySource, prices } = useStore()

  useEffect(() => {
    productWithHandles.forEach((product) => {
      if (prices[product.handle] === undefined) {
        availabilitySource?.getAvailability(product)
      }
    })
  }, [availabilitySource, prices, productWithHandles])

  useEffect(() => {
    const tempProducts = productWithHandles.map((p) => {
      const handle = p.handle
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const product: any = Object.assign({}, p)
      const productPrices = prices[handle]

      const price = productPrices?.price
      const compareAtPrice = productPrices?.price

      if (path) {
        set(product, `${path}.price`, price)
        set(product, `${path}.compareAtPrice`, compareAtPrice)

        return product
      }

      return {
        ...product,
        compareAtPrice,
        price,
        handle
      }
    })

    setDynamicProducts(tempProducts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWithHandles, prices])

  return dynamicProducts
}
