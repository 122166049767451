import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import {
  HomePageQuery,
  VariantWithProductFragment,
  ContentfulPromotionUnion
} from "../../graphql/types"
import { PageProps } from "../types/page"
import { Page } from "../components/page/page"
import { HomePageView } from "../features/home-page/components/home-page-view/home-page-view"
import {
  mapSourceUpcomingEventsToView,
  mapSourceUpcomingEventToView
} from "../features/home-page/mappers/upcoming-events-mapper"
import { mapSourceFeaturedItemsToView } from "../features/home-page/mappers/featured-items-mapper"
import { mapSourceListToView } from "../features/home-page/mappers/list-mapper"
import { mapCallToAction } from "../core/mappers/map-call-to-action"
import { mapVariantDetailsToView } from "../features/store/mappers/product-details-mapper"
import { mapProductDetailsToRelatedProduct } from "../features/store/mappers/store-common-mappers"
import { mapMetadata } from "../core/mappers/map-metadata"
import { YearEndHeaderData } from "@features/home-page/home-page-types"
import { mapYearEndHeader } from "@features/home-page/mappers/year-end-header-mapper"
import { mapPromotion } from "@features/home-page/mappers/promotion-mapper"
import { defaultMetadata, PageOpenGraphImage } from "@core/constants"

export const HomePage: FunctionComponent<PageProps<HomePageQuery>> = ({
  data,
  ...props
}) => {
  const homePageData = data!.contentfulHomePage!

  const metadataOverrides = {
    title:
      homePageData?.metadata?.title ||
      homePageData.pageTitle ||
      defaultMetadata.title,
    description:
      homePageData?.metadata?.description || defaultMetadata.description,
    openGraphImage:
      homePageData?.metadata?.image?.fixed?.src || PageOpenGraphImage.Home
  }

  const metadata = mapMetadata(homePageData.metadata, null, {
    title: homePageData.pageTitle,
    image: PageOpenGraphImage.Home
  })

  const upcomingEvents = mapSourceUpcomingEventsToView(data?.allEvent?.nodes)
  const featuredEvent = mapSourceUpcomingEventToView(
    homePageData?.featuredEvent
  )
  const displayFeaturedEvent = Boolean(homePageData?.displayFeaturedEvent)

  // TODO: Simplify this by reinstating related product fragment
  const fromStore = homePageData.fromTheStore
    ? homePageData.fromTheStore
        .map((p) => mapVariantDetailsToView(p as VariantWithProductFragment))
        .map(mapProductDetailsToRelatedProduct)
    : null

  const mappedPromotion = homePageData.homePagePromotion
    ? mapPromotion(homePageData.homePagePromotion as ContentfulPromotionUnion)
    : null

  const mappedYearEndHeader = homePageData.yearEndHeader
    ? mapYearEndHeader(homePageData.yearEndHeader as YearEndHeaderData)
    : null

  const featuredForYou = mapSourceFeaturedItemsToView(
    homePageData.featuredForYou
  )
  const additionalFeatured = mapSourceFeaturedItemsToView(
    homePageData.additionalFeatured
  )

  const callToAction = mapCallToAction(data?.contentfulHomePage?.callToAction)

  const popularList = mapSourceListToView(homePageData.popularList)
  const recentList = mapSourceListToView(homePageData.recentList)

  return (
    <Page
      {...props}
      isInfoIconShown={true}
      pageOptions={{ headerVariant: null }}
      metadata={{ ...metadata, ...metadataOverrides }}
      yearEndContent={mappedYearEndHeader}
    >
      <HomePageView
        fromStore={fromStore}
        upcomingEvents={upcomingEvents}
        featuredEvent={featuredEvent}
        displayFeaturedEvent={displayFeaturedEvent}
        featuredForYou={featuredForYou}
        additionalFeatured={additionalFeatured}
        customPromotion={mappedPromotion?.customPromotion}
        productPromotion={mappedPromotion?.productPromotion}
        popularList={popularList}
        recentList={recentList}
        callToAction={callToAction}
        yearEndHeader={mappedYearEndHeader}
      />
    </Page>
  )
}

export default HomePage

export const query = graphql`
  query HomePage {
    allEvent(limit: 3, sort: { fields: [startDate], order: ASC }) {
      nodes {
        ...EventNode
      }
    }
    contentfulHomePage(title: { eq: "Home Page" }) {
      pageTitle
      yearEndHeader {
        ...YearEndHeader
      }
      displayFeaturedEvent
      featuredEvent {
        ...Event
      }
      metadata {
        ...Metadata
      }
      fromTheStore {
        ...VariantWithProduct
      }
      popularList {
        ... on ContentfulArticle {
          ...FeaturedArticle
        }
        ... on ContentfulDevotional {
          ...FeaturedDevotional
        }
        ... on ContentfulPost {
          ...FeaturedPost
        }
        ... on ContentfulSeries {
          ...FeaturedSeries
        }
        ... on ContentfulSermon {
          ...FeaturedSermon
        }
        ... on ContentfulVideo {
          ...FeaturedVideo
        }
      }
      recentList {
        ... on ContentfulArticle {
          ...FeaturedArticle
        }
        ... on ContentfulDevotional {
          ...FeaturedDevotional
        }
        ... on ContentfulPost {
          ...FeaturedPost
        }
        ... on ContentfulSeries {
          ...FeaturedSeries
        }
        ... on ContentfulSermon {
          ...FeaturedSermon
        }
        ... on ContentfulVideo {
          ...FeaturedVideo
        }
      }
      homePagePromotion {
        ...Promotion
      }
      additionalFeatured {
        ... on ContentfulArticle {
          ...FeaturedArticle
        }
        ... on ContentfulDevotional {
          ...FeaturedDevotional
        }
        ... on ContentfulPost {
          ...FeaturedPost
        }
        ... on ContentfulSeries {
          ...FeaturedSeries
        }
        ... on ContentfulSermon {
          ...FeaturedSermon
        }
        ... on ContentfulVideo {
          ...FeaturedVideo
        }
      }
      featuredForYou {
        ... on ContentfulArticle {
          ...FeaturedArticle
        }
        ... on ContentfulDevotional {
          ...FeaturedDevotional
        }
        ... on ContentfulPost {
          ...FeaturedPost
        }
        ... on ContentfulSeries {
          ...FeaturedSeries
        }
        ... on ContentfulSermon {
          ...FeaturedSermon
        }
        ... on ContentfulVideo {
          ...FeaturedVideo
        }
      }
      callToAction {
        ...CallToAction
      }
    }
  }
`
