import { useQuery } from "react-query"
import { Entry } from "contentful"
import { FeaturedResourceEntry as DailyResourceEntryFields } from "@components/featured-resources/types"
import contentful from "@utils/contentful"

const today = new Date().toISOString()

const getDailyItem = async (contentType: string) => {
  const response = await contentful.getEntries({
    content_type: contentType,
    "fields.displayDate[lte]": today,
    "fields.expirationDate[gt]": today,
    order: "-fields.displayDate",
    limit: 1,
    include: 3
  })

  return response.items[0]
}

type DailyResourceEntry = Entry<DailyResourceEntryFields>

interface DailyResourceResponseFields {
  title?: string
  displayDate?: string
  devotional?: DailyResourceEntry
  video?: DailyResourceEntry
}

type DailyResourceResponse = Entry<DailyResourceResponseFields>

type DailyResourcesPromise = Promise<DailyResourceResponse[]>

export default function useDailyResources() {
  const { data } = useQuery("dailyResources", (): DailyResourcesPromise => {
    return <DailyResourcesPromise>(
      Promise.all([getDailyItem("dailyVideo"), getDailyItem("dailyDevotional")])
    )
  })

  const [dailyVideo, dailyDevotional] = data || []

  return {
    dailyVideo,
    dailyDevotional
  }
}
